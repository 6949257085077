import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "2021 年度新卒エンジニア研修について",
  "date": "2021-10-12T09:01:47.000Z",
  "slug": "entry/2021/10/12/180147",
  "tags": ["medley"],
  "hero": "./2021_10_12.png",
  "heroAlt": "新卒研修"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは。医療介護求人サイト「ジョブメドレー」の開発を担当しているエンジニアの山田です。 今年の新卒エンジニア研修において、メンターを担当しました。`}</p>
    <p>{`メドレーでは 2019 年度から新卒採用を行なっており、今年 2021 年度は 5 名の新卒がエンジニアとして入社しました。`}</p>
    <p>{`例年と同じく 4 月から 9 月にかけて、約 5 ヶ月間の新卒エンジニア研修を実施しましたので、その取り組みを、研修受講者である新卒からの声も交えてご紹介します。`}</p>
    <h1>{`新卒研修の概要`}</h1>
    <p>{`今年の新卒研修の最終ゴールは、「`}<strong parentName="p">{`メドレーのエンジニアとして、`}<a parentName="strong" {...{
          "href": "https://www.medley.jp/team/culture.html"
        }}>{`Our Essentials`}</a>{`(※) を体現し、顧客へ価値提供できるようになるための基礎を身につけ、経験を得ること`}</strong>{`」として掲げました。`}</p>
    <p><em parentName="p">{`※) メドレーの行動原則`}</em></p>
    <p>{`メドレーの新卒エンジニア研修では、技術を身につけることだけではなく、ビジネスパーソンとしての基礎を身につけ、メドレーが大切にしている価値観を理解し、体現する意識をもって、顧客への価値提供について自分の言葉で話せるようになることまでを目指してもらいます。`}</p>
    <p>{`研修は昨年同様、大きく分けて、4 つのフェーズに区切って行いました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20211013/20211013113305.png",
      "alt": "20211013113305.png"
    }}></img>
    <p>{`また、全研修期間を通じて、各新卒にはメンターを一人ずつ付けました。メンターは、一週間に 1~2 回のペースで新卒と 1on1 ミーティングを実施し、フィジカルとメンタルの両面を気遣い、個別にフォローを行いました。`}</p>
    <h1>{`新卒研修の内容`}</h1>
    <h2>{`フェーズ 1：社会人&メドレー基礎研修`}</h2>
    <h4>{`リスク研修`}</h4>
    <ul>
      <li parentName="ul">{`インサイダー取引防止研修`}</li>
      <li parentName="ul">{`コンプライアンス研修`}</li>
      <li parentName="ul">{`情報セキュリティ研修`}</li>
    </ul>
    <h4>{`ビジネス研修`}</h4>
    <ul>
      <li parentName="ul">{`ビジネスマナー研修`}</li>
      <li parentName="ul">{`ビジネススキル研修`}</li>
      <li parentName="ul">{`ビジネススタンス研修（外部研修）`}</li>
    </ul>
    <p>{`フェーズ 1 では、`}<strong parentName="p">{`成果を出し、価値を発揮するために必要なビジネスパーソンとしての基本的な仕事の型を身につけること`}</strong>{`をゴールとしました。`}</p>
    <p>{`リスク研修では、メドレー社員として、社会人として、身の周りで起こりうるリスクについて考え、いかにそれらのリスクと向き合うかを講義形式で学んでもらいました。`}</p>
    <p>{`ビジネス研修では、社会人としての最低限のマナーを学び、論理的思考力、コミュニケーション力など、エンジニア職に限らない課題解決力へつながるポータブルな知識を、座学とワークショップを通じて定着してもらうことを図りました。`}</p>
    <p>{`また、社会人の基準で仕事と向き合い、適切な報連相によって周囲と協働していくことの重要性についても学んでもらいました。`}</p>
    <h5>{`新卒からの声`}</h5>
    <blockquote>
      <ul parentName="blockquote">
        <li parentName="ul">{`質の高い、多量のインプット・アウトプットができた`}</li>
        <li parentName="ul">{`伝わるメールの書き方、名刺の渡し方など、社会人に必須のマナーやスキルを認識できた`}</li>
        <li parentName="ul">{`ワークを通じて、言葉では理解していても行動するとできないことを洗い出せた`}</li>
      </ul>
    </blockquote>
    <h2>{`フェーズ 2：エンジニア基礎研修`}</h2>
    <h4>{`開発基礎 1`}</h4>
    <ul>
      <li parentName="ul">{`メドレーエンジニアとして求めること`}</li>
      <li parentName="ul">{`事業（ジョブメドレー ・ CLINICS ・介護のほんね）の概要説明`}</li>
      <li parentName="ul">{`開発基礎研修（Ruby on Rails チュートリアル）`}</li>
      <li parentName="ul">{`開発実践 （要件定義〜リリースまで）`}</li>
    </ul>
    <h4>{`開発基礎 2`}</h4>
    <ul>
      <li parentName="ul">{`技術書の輪読会`}</li>
      <li parentName="ul">{`ドキュメンテーションスキル研修`}</li>
      <li parentName="ul">{`プレゼンテーションスキル研修`}</li>
      <li parentName="ul">{`中間レポート作成`}</li>
      <li parentName="ul">{`中間報告会`}</li>
    </ul>
    <p>{`フェーズ 2 では、新卒研修後に開発業務に入ってもらえるよう、`}<strong parentName="p">{`エンジニアとしての基礎を身につけること`}</strong>{`をゴールとしました。`}</p>
    <h3>{`メドレーエンジニアとして求めること`}</h3>
    <p>{`開発に関わるこのフェーズにおいても、要件定義を含む汎用的な技術的スキルは勿論のこと、メドレーエンジニアが共通して持つべき価値観などを共有するため、フェーズ 2 初日は「`}<strong parentName="p">{`メドレーエンジニアとして求めること`}</strong>{`」と題して、エンジニアの執行役員 田中が講義を行いました。`}</p>
    <p>{`講義では、「`}<strong parentName="p">{`エンジニア`}</strong>{`とは、`}<strong parentName="p">{`エンジニアの価値`}</strong>{`とは、`}<strong parentName="p">{`プロエンジニア`}</strong>{`とはなんでしょうか？」という問いから始まり、講義の終わりにはもう一度同じ問いかけをして締めくくり、新卒がメドレーの求めるエンジニア像について自身の言葉で話せるように考えてもらいました。`}</p>
    <p>{`メドレーが求めるエンジニア像については、CTO 平山の`}<a parentName="p" {...{
        "href": "https://toppa.medley.jp/05.html"
      }}>{` メドレー平山の中央突破: THE エンジニア`}</a>{` にも書かれていますので、よろしければ、あわせてご覧ください。`}</p>
    <p>{`さらに、メドレーが展開する各事業および関連するプロダクトの概要説明をプロダクトマネージャーが行い、メドレーで開発する意義をあらためて認識してもらいました。`}</p>
    <h3>{`開発基礎研修`}</h3>
    <p>{`2 日目より、`}<a parentName="p" {...{
        "href": "https://railstutorial.jp/"
      }}>{`Ruby on Rails チュートリアル`}</a>{`（以下、「Rails チュートリアル」）を教材とした、開発基礎研修に移りました。`}</p>
    <p>{`メドレーのプロダクトは Rails で作られているものが多く、Web アプリケーションを開発するための基礎を身につけるためにも、Rails チュートリアルの内容を実施してもらいました。`}</p>
    <p>{`単純に、Rails チュートリアルの内容に沿って、ダラダラと写経するのではなく、随時、学んだことは Confluence にまとめ、GitHub 上で Pull Request を作成する形で、ソースコードを共有してもらいました。`}</p>
    <p>{`学んだことを自分の言葉に置き換えてアウトプットすることで反復学習を促し、Pull Request を作成してもらうことで GitHub の使い方に慣れてもらうことを図りました。`}</p>
    <p>{`また、デイリーで朝会と夕会を実施しました。朝会は仕事のリズムを整えるための顔合わせ、夕会は新卒から質問・成果を共有してメンターがそれに対してフィードバックをする場としてそれぞれ実施しました。`}</p>
    <p>{`研修前に既に Rails チュートリアルを一周していた新卒もいましたが、二周目を実施して新たな気付きを得たり、AWS を用いてクラウド上に環境構築し、作成した Web アプリケーションをデプロイするまでを実践してもらうなど、インフラに関しても理解を深めてもらうことができました。`}</p>
    <h5>{`新卒からの声`}</h5>
    <blockquote>
      <ul parentName="blockquote">
        <li parentName="ul">{`システムのパフォーマンスを上げるための工夫を知ることができた`}</li>
        <li parentName="ul">{`バグ発生〜原因特定〜修正、というデバッグのスピードが、研修序盤から飛躍的に上がった`}</li>
        <li parentName="ul">{`プロダクトで利用している AWS の各種サービスの概要を理解できたことに加え、サービス間の繋がりやネットワークの流れに関しても理解を深めることができた`}</li>
      </ul>
    </blockquote>
    <h3>{`開発実践`}</h3>
    <p>{`開発基礎研修にて Web アプリケーション開発の基礎を学んだ後は、「`}<strong parentName="p">{`メドレー/グループ会社で使う来訪者受付システム`}</strong>{`」を開発題材として、開発実践研修を行いました。`}</p>
    <p>{`開発業務全体の流れを把握することで、`}<strong parentName="p">{`チームで開発（課題解決）することを経験し、今後の仕事に役立たせること`}</strong>{`を目的としました。`}</p>
    <p>{`本研修で達成すべきこととして掲げていたものは主に、次の通りです。`}</p>
    <ul>
      <li parentName="ul">{`プロジェクト管理能力を身につけること`}</li>
      <li parentName="ul">{`開発する対象を体系的に整理できる能力を養うこと`}</li>
      <li parentName="ul">{`システム設計に関する基礎的な物事を理解すること`}</li>
      <li parentName="ul">{`チーム開発を理解すること`}</li>
      <li parentName="ul">{`品質を理解すること`}</li>
    </ul>
    <p>{`既に決まりきった仕様書に沿って開発するのではなく、新卒自身が現状の問題把握や課題整理を行って、ユーザーへ価値提供するために何を作るべきかを考えることから始まり、リリース後の運用方法やランニングコストのことまで考え提案してもらいました。`}</p>
    <p>{`開発実践研修は約 1 ヶ月の期間をかけて行いました。大まかな流れとしては、次の通りです。`}</p>
    <ol>
      <li parentName="ol">{`要件定義（ヒアリング・現状把握・課題整理・要求分析・機能/非機能要件の洗い出し・ UI 草案）`}</li>
      <li parentName="ol">{`プロジェクト計画（役割分担・ WBS/ガントチャート作成）`}</li>
      <li parentName="ol">{`設計（画面設計・機能設計・データモデリング・方式設計・インフラ設計）`}</li>
      <li parentName="ol">{`開発（実装・コードレビュー）`}</li>
      <li parentName="ol">{`QA（テスト設計・テスト）`}</li>
      <li parentName="ol">{`成果発表（成果物を関係者へプレゼン・リリース）`}</li>
    </ol>
    <p>{`方式設計の一部として、開発に使用する言語などの選定も新卒自身が行いました。`}</p>
    <p>{`今回作成した社員向け管理画面と来訪者向け画面はいずれも SPA（一部、PWA）のアーキテクチャを採用し、主なライブラリ/フレームワークに関して、フロントエンドは`}<a parentName="p" {...{
        "href": "https://www.typescriptlang.org/"
      }}>{`TypeScript`}</a>{`, `}<a parentName="p" {...{
        "href": "https://ja.reactjs.org/"
      }}>{`React`}</a>{`, `}<a parentName="p" {...{
        "href": "https://nextjs.org/"
      }}>{`Next.js`}</a>{`, `}<a parentName="p" {...{
        "href": "https://chakra-ui.com/"
      }}>{`Chakra UI`}</a>{`, `}<a parentName="p" {...{
        "href": "https://ionicframework.com/jp/docs/"
      }}>{`Ionic Framework`}</a>{`、バックエンドは Ruby on Rails（API モード）をそれぞれ利用することとなりました。`}</p>
    <p>{`選定理由としては主に、次の通りです。`}</p>
    <ul>
      <li parentName="ul">{`TypeScript, React（両画面共通）`}
        <ul parentName="li">
          <li parentName="ul">{`ロジックからテンプレートまでの全てのコードを静的型付けで書くことができ、堅牢性に優れているため`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Next.js, Chakra UI（社員向け管理画面）`}
        <ul parentName="li">
          <li parentName="ul">{`ゼロコンフィグでビルドやレンダリングを最適化できるため`}</li>
          <li parentName="ul">{`アクセシビリティに優れたリッチな UI を素早く構築できるため`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Ionic Framework（来訪者向け画面）`}
        <ul parentName="li">
          <li parentName="ul">{`iPad 上で、ネイティブアプリのような UI/UX を提供するため`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Ruby on Rails API モード`}
        <ul parentName="li">
          <li parentName="ul">{`フロントエンドとバックエンドを分離して疎結合にするため`}</li>
          <li parentName="ul">{`短期間で構築するため`}</li>
          <li parentName="ul">{`社内でもよく使われておりメンテナンスしやすいため`}</li>
        </ul>
      </li>
    </ul>
    <p>{`インフラは AWS を採用し、EC2, S3, RDS, CloudFront, Route53, CloudWatch などのサービスを利用しました。`}</p>
    <p>{`結果的に、本研修プログラムの成果物としてリリースされたシステムは「`}<strong parentName="p">{`Medley Entrance`}</strong>{`」という名前で、社内ツールとして現在、毎日稼働しており、ユーザーとしてメドレー/グループ社員だけではなく、来訪者の方々にも使っていただいています。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20211007/20211007112134.png",
      "alt": "20211007112134.png"
    }}></img>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20211007/20211007112210.png",
      "alt": "20211007112210.png"
    }}></img>
    <p><em parentName="p">{`Medley Entrance（上：社員向け管理画面、下：来訪者向け画面）`}</em></p>
    <p>{`チームで課題解決に臨み、価値提供までの実績を残せたことは自信につながり、開発実践研修のやりがいとして、感じてもらえたのではないでしょうか。`}</p>
    <p>{`要件定義などの期間中、想定よりもスムーズに進められなかった時も他責にせず、各々がリーダーシップを発揮し、建設的に進めていく新卒の様子をメンターの一人として傍で見させてもらいました。`}</p>
    <p>{`この 1 ヶ月間の開発実践研修を通じて、技術力はさることながら、課題解決に対する十分な熱意と主体性を新卒から感じられ、とても頼もしい印象として残りました。`}</p>
    <h5>{`新卒からの声`}</h5>
    <blockquote>
      <ul parentName="blockquote">
        <li parentName="ul">{`開発の中での方針を意識して設計/実装することができた(シンプルにする)`}</li>
        <li parentName="ul">{`QA とはそもそも何かというリサーチから入り、有識者の考え方を軸に方針を決めてから始められた`}</li>
        <li parentName="ul">{`各々が最適なパフォーマンスを発揮できる環境づくりを意識して、高速な意思決定が可能な体制を整えることができた`}</li>
        <li parentName="ul">{`要件が決まりきっていない中で設計するのは難しかった`}</li>
        <li parentName="ul">{`開発タスクが集中していた時に、プロジェクト全体の現状を把握できていなかった`}</li>
        <li parentName="ul">{`文章を作るスキルが足りていない`}</li>
      </ul>
    </blockquote>
    <h3>{`技術書の輪読会`}</h3>
    <p>{`フェーズ 2 の開発基礎 2 の輪読会では、 `}<a parentName="p" {...{
        "href": "https://www.amazon.co.jp/dp/4774142042/"
      }}>{`『Web を支える技術 -HTTP、URI、HTML、そして REST』`}</a>{` を題材書籍として、7 日間に渡って毎日、次の手順で実施しました。`}</p>
    <ol>
      <li parentName="ol">{`参加者が同じパートをあらかじめ読んでおき、書籍から学んだこと、ネットなどで調べても解消しきれなかった疑問点などをまとめる`}</li>
      <li parentName="ol">{`その内容をもとに、夕方のミーティング時において、各自が発表してディスカッションを行う`}</li>
      <li parentName="ol">{`ディスカッションした内容は議事録にまとめる`}</li>
    </ol>
    <p>{`輪読会は他者からの学びを共有してもらうことで、自分にはなかった視点・気付きを獲得し、その書籍への理解をより深められる効果があります。`}</p>
    <p>{`本研修プログラムにおける輪読会の目的としては、`}<strong parentName="p">{`Web サービスを開発していく上で必要となる知識へ触れることにより、今後獲得していくべき知識のベースラインを理解すること`}</strong>{` でしたが、輪読会ならではのメリット・楽しさを新卒に実感してもらえたことも、副次的な効果としてあったと思います。`}</p>
    <h5>{`新卒からの声`}</h5>
    <blockquote>
      <ul parentName="blockquote">
        <li parentName="ul">{`Web の基本的な知識を「なぜ登場したのか」を理解しながら網羅的に学ぶことができた`}</li>
        <li parentName="ul">{`文書でまとめた後に、口で説明することが学んだ内容の定着に良いと感じた`}</li>
        <li parentName="ul">{`これまでなんとなく実装していたことの仕組みを学ぶことで、知識として定着することができた`}</li>
      </ul>
    </blockquote>
    <h3>{`中間報告会`}</h3>
    <p>{`フェーズ 2：エンジニア基礎研修最後の研修プログラムである中間報告会に向けて、ドキュメンテーションスキル研修、プレゼンテーションスキル研修を実施しました。`}</p>
    <p>{`上記スキルが必要となる背景は、次の通りです。`}</p>
    <ul>
      <li parentName="ul">{`エンジニアリングを通じた課題解決とはプログラムを書くだけでは解決しない場面もある`}</li>
      <li parentName="ul">{`背景、目的を正しくステークホルダーへ共有しながらチームとして取り組んでいくことになる`}</li>
      <li parentName="ul">{`伝えたいことを文章として整理し、他者へ分かりやすく伝えていくことが求められる`}</li>
    </ul>
    <p>{`また、メドレーの行動原則 `}<a parentName="p" {...{
        "href": "https://www.medley.jp/team/culture.html"
      }}>{`Our Essentials`}</a>{` を構成する要素として、「ドキュメントドリブン」「全てを明確に」という項目が含まれており、これらを実現するためにも、とても重要なスキルとしてメドレーは考えています。`}</p>
    <p>{`新卒研修が終わった後も、エンジニアとして技術的なスキルを身につける機会は日常的に多くありますが、上記のようなスキルをまとめて習得する機会は少ないため、このような研修を社会人のはじめから受けておくことで、その後の伸びしろが違ってくるのではないかと思います。`}</p>
    <p>{`研修が終わった後は、各自で報告会用の資料を作成し、研修講師からの添削を受けました。`}</p>
    <p>{`中間報告会は各部署の開発マネージャーを発表相手として、当日は程よい緊張感をもって、良い雰囲気で報告会を終えられました。`}</p>
    <h2>{`フェーズ 3：事業部 OJT`}</h2>
    <h3>{`事業部研修`}</h3>
    <ul>
      <li parentName="ul">{`取締役豊田からの講義`}</li>
      <li parentName="ul">{`CLINICS 事業部研修`}</li>
      <li parentName="ul">{`ジョブメドレー事業部研修`}</li>
    </ul>
    <h3>{`開発 OJT`}</h3>
    <ul>
      <li parentName="ul">{`システム全体像説明`}</li>
      <li parentName="ul">{`環境構築`}</li>
      <li parentName="ul">{`各プロダクトの開発チームでの OJT`}</li>
    </ul>
    <p>{`フェーズ 3 では、`}<strong parentName="p">{`顧客の課題と、顧客への価値提供のための各チームの連携を体感し、メドレーの顧客提供価値を自分の言葉で話せるようになること`}</strong>{`をゴールとしました。`}</p>
    <p>{`フェーズ 3 のはじめに、取締役豊田による日本の医療の課題とメドレーの取り組みに関する講義を受講してもらいました。`}</p>
    <p>{`持続可能な医療体制を構築していくためにメドレーが成すべきことなどの話を聞いた後に、新卒からの質問の受け答えによって理解を深め、メドレーの社会的意義をあらためて認識してもらい、エンジニアとしてだけではなく、`}<strong parentName="p">{`メドレー社員としての自覚`}</strong>{`を強めてもらいました。`}</p>
    <h3>{`事業部研修`}</h3>
    <p>{`開発 OJT で手を動かす前に、自分たちが何のために開発するのかを具体的にイメージできるよう、次のように、各現場に参加してもらいました。`}</p>
    <ul>
      <li parentName="ul">{`見込顧客への架電業務見学`}</li>
      <li parentName="ul">{`商談前の社内ミーティング参加`}</li>
      <li parentName="ul">{`商談現場同席`}</li>
      <li parentName="ul">{`定例会議参加`}</li>
    </ul>
    <p>{`事業部のスタッフが、顧客の課題に対して、どのような対応をしていて、どのようにプロダクトを説明しているのか、事業部の各チームが、どのように連携して最終的に顧客に価値を届けるのかの全体観を知ってもらうことを狙いとしていました。`}</p>
    <p>{`開発チームのエンジニアは業務上、プロダクトのエンドユーザーである顧客の声などを商談のタイミングから聞ける機会はなかなか無いので、研修を通じて話を聞けたことは、今後の開発モチベーションにも影響する良い機会だったと思います。`}</p>
    <h5>{`新卒からの声`}</h5>
    <blockquote>
      <ul parentName="blockquote">
        <li parentName="ul">{`ユーザー、顧客、事業部が抱える課題を確認できたことで、開発以外にも目を向けるきっかけになり良かった`}</li>
        <li parentName="ul">{`各部署が KPI として定めている数字を知ることができ、開発に降りてきている施策の影響箇所がどの部分かを理解できた上で、開発に取り組むことができるようになった`}</li>
        <li parentName="ul">{`各部署のミーディングに参加することで、各部署がどのような考えで何を目指しているのかを理解でき、メドレー全体として目指している方向性が掴めた`}</li>
      </ul>
    </blockquote>
    <h3>{`開発 OJT`}</h3>
    <p>{`事業部研修に続く開発 OJT では、`}<a parentName="p" {...{
        "href": "https://job-medley.com/"
      }}>{`ジョブメドレー`}</a>{` 、`}<a parentName="p" {...{
        "href": "https://clinics.medley.life/"
      }}>{`CLINICS`}</a>{`、`}<a parentName="p" {...{
        "href": "https://www.kaigonohonne.com/"
      }}>{`介護のほんね`}</a>{`の開発チームに分かれて、研修を実施しました。`}</p>
    <p>{`OJT 配属先では、メンターとは別に、トレーナーを付けて業務の進め方などをサポートしました。トレーナーは配属先の先輩エンジニアが担当しました。`}</p>
    <p>{`OJT の流れとしては、初日に、プロダクトがどのように動いているのか、システム全体像を把握することから始まり、各自、ドキュメントに沿って、PC にローカル開発環境をセットアップしました。`}</p>
    <p>{`その後は、他の先輩エンジニアと同様に、GitHub Issue で管理されている課題を解消することを日々の目標としてこなしてもらいました。ただし、単に Issue に書かれている課題をクリアしようとするのではなく、`}<strong parentName="p">{`そもそも、なぜそれをやるのか、Issue の背景や起票者の意図を十分に理解した上で、プロダクトのあるべき姿に導く`}</strong>{`ことを意識してもらいました。`}</p>
    <p>{`Issue に書かれている内容の理解が不十分だったり、解決方針がうまく定まらない場合は随時、ミーティングの時間を設けて、Issue 起票者やトレーナーと認識合わせを行い、認識の相違から生まれるミスコミュニケーションを極力減らすよう、取り組みました。`}</p>
    <p>{`技術的な質問に関しても、定期的に質問タイムを設けたり、複雑になりそうな実装や、つまずきポイントとなりそうな箇所に関しては、`}<strong parentName="p">{`画面共有を用いてレビュー`}</strong>{`を行い、疑問点に関してもその場で確認して、解消してもらいました。`}</p>
    <p>{`緊急事態宣言期間中だったため、会社全体で原則、在宅勤務の体制となっており、対面でのコミュニケーションが希薄になりがちでしたが、朝会、夕会を含め、たとえ新卒から質問が無くても質問タイムでのミーティングは定期的に実施するなど、`}<strong parentName="p">{`できるだけ頻繁に顔合わせして、新卒本人の声と顔を確認する`}</strong>{`よう心がけました。`}</p>
    <p>{`Issue へのアサインから始まって、実装 -> レビュー依頼 -> QA -> リリース -> Issue 起票者への報告まで、一連の開発フローを経験してもらい、チーム内での開発業務に慣れてもらうことができました。`}</p>
    <h2>{`フェーズ 4：最終報告`}</h2>
    <p>{`新卒研修最後のプログラムとして、メドレー役員陣に向けた最終報告会を実施しました。`}</p>
    <p>{`最終報告会の目的としては、次の通りです。`}</p>
    <ul>
      <li parentName="ul">{`学んだことの知識を深化させる`}</li>
      <li parentName="ul">{`自らの得手・不得手を捉え、将来の成長計画を立てる`}</li>
      <li parentName="ul">{`体系的に整理・文書化して他者へ伝えるスキルを向上させる`}</li>
      <li parentName="ul">{`役員陣に向けてプレゼンすることで、本配属に向けた決意表明として区切りを付ける`}</li>
    </ul>
    <p>{`役員陣への発表であることに加え、一人あたりの発表時間にも制限が設けられており、当日の緊張はかなりのものだったと思います。`}</p>
    <p>{`前日に発表会場を下見して、リハーサルを入念に行うなど、当日の発表会を成功させるため、メドレーのエンジニアとしての自覚を持って、発表準備に取り組んでいました。`}</p>
    <h1>{`技術志向とプロダクト志向の両輪を目指すエンジニア募集中`}</h1>
    <p>{`メドレーの研修では、技術的な講義や実践だけで終わるのではなく、ビジネスパーソンとして必要な基礎も身につけ、なぜ開発するのかを追究し、プロダクトを通じた課題解決を実体験してもらうことを重視しています。`}</p>
    <p>{`メドレーでは、医療ヘルスケア分野の課題解決に挑みたいエンジニアを募集しています。`}</p>
    <p>{`新卒の学生に限らず、中途採用も行っているので、エンジニアの方で少しでも興味を持っていただけたら、是非、面談でお話ししましょう。`}</p>
    <p>{`最後までお読みいただき、誠にありがとうございました。`}</p>
    <p>{`P.S.`}</p>
    <p>{`昨年、一昨年の新卒研修の様子はこちらより、それぞれご覧いただけます。`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/entry/2020/09/25/174527"
        }}>{`2020 年度新卒エンジニア研修について`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/entry/2019/12/16/165947"
        }}>{`2019 年度新卒エンジニア研修について`}</a></li>
    </ul>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      